<template>
  <div>
    <title-bar title="Benutzer bearbeiten">
      <router-link slot="right" to="/users" class="button is-primary">
        <b-icon
          :icon="$func.getIcon('back').icon"
          :pack="$func.getIcon('back').pack"
          class="mr-1"
        />
        Benutzer Übersicht
      </router-link>
    </title-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Details"
          :icon="$func.getIcon('user').icon"
          :icon-pack="$func.getIcon('user').pack"
          class="tile is-child"
        >
          <form @submit.prevent="submitUser">
            <b-field label="ID" horizontal>
              <b-input v-model="user.id" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Angelegt am" horizontal>
              <b-input
                v-model="createdReadable"
                custom-class="is-static"
                readonly
              />
            </b-field>

            <hr />

            <b-field label="Name" horizontal>
              <b-input
                v-model="user.name"
                placeholder="e.g. John Doe"
                required
              />
            </b-field>
            <b-field label="E-Mail" horizontal>
              <b-input
                v-model="user.email"
                placeholder="name@example.com"
                required
                type="email"
              />
            </b-field>

            <hr />

            <b-field horizontal>
              <b-button
                type="is-primary"
                native-type="submit"
                :icon-left="$func.getIcon('save').icon"
                :icon-pack="$func.getIcon('save').pack"
              >
                Update Benutzer
              </b-button>
            </b-field>

            <b-loading :is-full-page="false" :active="isLoading.user" />
          </form>
        </card-component>
        <card-component
          title="Passwort ändern"
          :icon="$func.getIcon('password').icon"
          :icon-pack="$func.getIcon('password').pack"
          class="tile is-child"
        >
          <form @submit.prevent="submitPassword">
            <b-field label="Neues Passwort" horizontal :type="passwordMatch">
              <b-input
                v-model="password"
                name="password"
                type="password"
                minlength="8"
                autocomplete="new-password"
              />
            </b-field>
            <b-field horizontal :type="passwordMatch" label="Bestätigen">
              <b-input
                v-model="password_confirmation"
                name="password_confirmation"
                type="password"
                minlength="8"
                autocomplete="new-password"
              />
            </b-field>
            <hr />
            <b-field horizontal>
              <div class="control">
                <b-button
                  native-type="submit"
                  class="is-primary"
                  :icon-left="$func.getIcon('save').icon"
                  :icon-pack="$func.getIcon('save').pack"
                >
                  Passwort ändern
                </b-button>
              </div>
            </b-field>

            <b-loading :is-full-page="false" :active="isLoading.password" />
          </form>
        </card-component>
      </tiles>
    </section>

    <b-loading :is-full-page="true" :active="isLoading.all" />
  </div>
</template>

<script>
import api from "@/utils/api.js";
import TitleBar from "@/components/TitleBar";
import Tiles from "@/components/Tiles";
import CardComponent from "@/components/CardComponent";

export default {
  name: "UserEdit",
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      isLoading: {
        user: false,
        password: false,
        all: false
      },
      user: this.getClearFormObject(),
      createdReadable: null,
      verifiedReadable: null,
      password: null,
      password_confirmation: null,
      isProfileExists: false,
      roles: [],
      assignedRoles: []
    };
  },
  computed: {
    passwordMatch() {
      if (this.password !== this.password_confirmation) {
        return "is-danger";
      } else {
        return "";
      }
    }
  },
  watch: {
    id(newValue) {
      this.isProfileExists = false;
      if (!parseInt(newValue)) {
        this.user = this.getClearFormObject();
      } else {
        this.getData();
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getClearFormObject() {
      return {
        id: null,
        name: null
      };
    },
    getData() {
      if (Number.isInteger(parseInt(this.$route.params.id))) {
        this.isLoading.all = true;
        api
          .get(`/users/${this.$route.params.id}`)
          .then(r => {
            this.isProfileExists = true;

            this.user = r.data;

            this.createdReadable = this.$moment(r.data.created_at).format();

            if (r.data.email_verified_at != null) {
              this.verifiedReadable = this.$moment(
                r.data.email_verified_at
              ).format();
            }
          })
          .finally(() => (this.isLoading.all = false));
      }
    },
    submitUser() {
      this.isLoading.user = true;
      api
        .put(`/users/${this.user.id}`, this.user)
        .then(() => {
          this.$buefy.snackbar.open({
            message: "Benutzer aktualisiert",
            queue: false
          });
          this.$router.push({ name: "users" });
        })
        .finally(() => (this.isLoading.user = false));
    },
    submitPassword() {
      if (this.password !== this.password_confirmation) {
        this.$buefy.toast.open({
          message: `Fehler: Passwörter stimmen nicht überein`,
          type: "is-danger",
          queue: false
        });
      } else {
        this.isLoading.password = true;
        api
          .put(`/users/${this.user.id}`, {
            password: this.password,
            disable: this.user.disabled,
            deleted: this.user.deleted
          })
          .then(() => {
            this.password = null;
            this.$buefy.snackbar.open({
              message: "Passwort aktualisiert",
              queue: false
            });
            this.$router.push({ name: "users" });
          })
          .finally(() => (this.isLoading.password = false));
      }
    },
    submitVerifyUser() {
      this.isLoading.user = true;
      api
        .put(`/users/${this.user.id}`, {
          email_verified_at: this.$moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: "Benutzer wurde verifiziert",
            queue: false
          });
          this.getData();
        })
        .finally(() => (this.isLoading.user = false));
    }
  }
};
</script>
